import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SeminarsContext from '../../context/seminars/seminarsContext';

import SeminarPage from '../Seminar/SeminarPage';

import LoadingSpinner from '../Feedback/LoadingSpinner';

const CenteredSpinner = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SeminarDetails = () => {
  const history = useHistory();
  const seminarsContext = useContext(SeminarsContext);

  useEffect(() => {
    if (!seminarsContext.current) {
      history.push('/');
    }
  }, [seminarsContext, history]);

  return (
    <>
      {!!seminarsContext.current ? (
        <SeminarPage />
      ) : (
        <CenteredSpinner>
          <LoadingSpinner />
        </CenteredSpinner>
      )}
    </>
  );
};

export default SeminarDetails;
