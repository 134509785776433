import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const blockStyles = ({ block }) => {
  if (block === 'true') {
    return css`
      display: block;
      width: 100%;
    `;
  } else {
    return css`
      display: inline-block;
    `;
  }
};

const sizeStyles = ({ size }) => {
  if (size === 'small') {
    return css`
      font-size: 0.8rem;
      padding: 0.3rem 1rem;
      margin-right: 0.2rem;
    `;
  } else if (size === 'large') {
    return css`
      font-size: 1.5rem;
      padding: 0.6rem 2rem;
      margin-right: 0.4rem;
    `;
  } else {
    return css`
      font-size: 1rem;
      padding: 1rem 1.5rem;
      margin-right: 0.5rem;
    `;
  }
};

const colorStyles = ({ color }) => {
  if (color === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'outline-light') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (color === 'outline-dark') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  }
};

export const LinkButtonExternal = styled.a`
  font-weight: 300;
  outline: none;
  text-transform: capitalize;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  white-space: nowrap;
  text-align: center;
  ${blockStyles}
  ${colorStyles}
  ${sizeStyles}
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

LinkButtonExternal.propTypes = {
  color: PropTypes.oneOf([
    'dark',
    'alt',
    'light',
    'outline-light',
    'outline-dark',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  block: PropTypes.oneOf(['true']),
};
