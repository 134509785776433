import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '1rem 0')};
  height: 1px;
  max-width: 800px;
  background-color: ${(props) =>
    `rgba(${props.theme.colors.neutral[3]}, 0.35)`};
`;

const LineBreak = ({ margin }) => {
  return <Line margin={margin} />;
};

export default LineBreak;
