import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Countdown from '../UI/Countdown';
import { LinkButton } from '../Controls';
import Live from '../../assets/live.png';

const ImgCard = styled.div``;
const DetailsLink = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
`;

const Card = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 10px;
  height: 200px;
  width: 100%;
  max-width: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
      0deg, rgba(${props.theme.colors.primary[0]}, 0.8), rgba(${props.theme.colors.neutral[0]}, 0)), url(${props.img}) `};
`;

const Time = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  font-size: 1rem;
  display: flex;
  justify-content: center;
  max-width: 500px;
  > * {
    margin: 0 0.5rem;
  }
  padding: 0.5rem;
`;

const TextBlock = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 0.4)`};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem;
`;

const TextGroup = styled.div`
  width: 80%;
`;

const Title = styled.div`
  font-size: 1rem;
  padding-bottom: 0.25rem;
`;

const Description = styled.div`
  font-size: 0.8rem;
  opacity: 0.8;
`;

const ImgTile = styled.img`
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin-right: 0.5rem;
  min-height: 60px;
  min-width: 60px;
`;
const Details = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
  font-size: 0.9rem;
`;
const DetailsTitle = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
  padding-bottom: 0.25rem;
  font-size: 0.7rem;
`;

const LargeSeminarCard = ({ seminar, authID }) => {
  return (
    <>
      <ImgCard>
        <Card img={seminar.thumbnailImgUrl}>
          <TextBlock>
            <TextGroup>
              <Title>{seminar.title}</Title>
              <Description>
                {!!seminar.description && seminar.description.substring(0, 80)}
                ...
              </Description>
            </TextGroup>
          </TextBlock>
        </Card>
      </ImgCard>
      <Time>
        <Countdown TargetDate={seminar.startTime} title='Starts In:' />
      </Time>
      <DetailsLink>
        <ImgTile src={Live} alt='live' />
        <Details>
          <DetailsTitle>Start Time</DetailsTitle>
          <p> {moment(seminar.startTime).format('ddd Do MMMM YYYY h:mm a')}</p>
        </Details>{' '}
        {!!seminar.registeredUsers &&
        seminar.registeredUsers.includes(authID) ? (
          <LinkButton
            size='small'
            color='light'
            bold='true'
            to='/seminars/details'
          >
            CALL DETAILS
          </LinkButton>
        ) : (
          <LinkButton
            size='small'
            color='light'
            bold='true'
            to='/seminars/register'
          >
            REGISTER
          </LinkButton>
        )}
      </DetailsLink>
    </>
  );
};

export default LargeSeminarCard;
