import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';

import { Button } from '../Controls';
import Alerts from '../Feedback/Alerts';

import Logo from '../../assets/logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  margin: 0;
  margin: auto;
  padding: 1rem;
  max-height: 800px;
`;

const StyledLogo = styled.img`
  margin: auto;
  width: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
`;
const Intro = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1rem;
`;

const VerifyEmail = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const {
    error,
    clearErrors,
    isAuthenticated,
    verifyEmail,
    user,
    loadUser,
  } = authContext;

  useEffect(() => {
    if (!user) {
      loadUser();
    }
    if (!!user && !!user.emailVerified) {
      props.history.push('/');
    }

    if (!!error && error.code === 'email-verify-danger') {
      setAlert(error.message, 'success-mail');
      clearErrors();
    } else if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }
  }, [
    error,
    isAuthenticated,
    props.history,
    user,
    clearErrors,
    setAlert,
    loadUser,
  ]);

  const [requested, setRequested] = useState(false);

  const handleVerificationRequest = () => {
    verifyEmail();
    setRequested(true);
  };

  return (
    <Wrapper>
      <StyledLogo alt='logo' src={Logo} />
      <Alerts />
      <Intro>
        Hello
        {!!user && !!user.disIntrolayName ? ` ${user.displayName} - ` : ''}, for
        security your email address needs to be verified before you can access
        your account.
      </Intro>
      <Button
        onClick={handleVerificationRequest}
        block={'true'}
        color={'success'}
        fontSize={1.2}
        disabled={requested}
      >
        {!requested ? 'Verify Email' : 'Requested'}
      </Button>
    </Wrapper>
  );
};

export default VerifyEmail;
