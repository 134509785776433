import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import AlertContext from '../../context/alert/alertContext';

import Icon from '../UI/Icon';

const colorStyles = ({ color }) => {
  if (color === 'success') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.success[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'danger' || color === 'danger-mail') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.danger[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  }
};

const StyledAlert = styled.div`
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
  display: flex;
  ${colorStyles}
`;

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map((alert) => (
      <StyledAlert key={alert.id} color={alert.type}>
        <Icon
          iconType={alert.type === 'danger-mail' ? 'mail' : 'infoCircle'}
          size={1}
          color='light'
          margin={'0 0.5rem'}
        />
        {alert.msg}
      </StyledAlert>
    ))
  );
};

Alerts.propTypes = {
  type: PropTypes.oneOf(['danger', 'success']),
};

export default Alerts;
