import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  AiOutlineTwitter,
  AiFillPlusCircle,
  AiFillYoutube,
  AiOutlineVideoCamera,
  AiOutlineMail,
  AiOutlineShop,
  AiOutlinePlaySquare,
  AiOutlineBulb,
  AiOutlineArrowUp,
  AiOutlinePlayCircle,
  AiOutlineSearch,
  AiOutlineClockCircle,
  AiOutlineCopy,
  AiOutlineUserAdd,
  AiOutlineClose,
  AiOutlineCaretDown,
  AiOutlineRightCircle,
  AiOutlineDashboard,
  AiOutlinePieChart,
  AiOutlineOrderedList,
  AiOutlineLock,
  AiOutlineUsergroupAdd,
  AiOutlineMenu,
  AiOutlineCalendar,
  AiOutlineFileText,
  AiOutlineFolderOpen,
  AiOutlineOneToOne,
  AiOutlineStop,
  AiOutlineLogin,
  AiOutlineAppstoreAdd,
  AiOutlineExclamationCircle,
  AiOutlineCalculator,
  AiOutlineInfoCircle,
  AiOutlineHistory,
  AiOutlineFilePdf,
  AiOutlineFileImage,
  AiOutlineSetting,
  AiOutlineComment,
  AiOutlineReconciliation,
  AiOutlineSolution,
  AiOutlineHdd,
  AiOutlineGlobal,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillCopy,
  AiOutlineFileDone,
  AiOutlineCluster,
  AiOutlineFileProtect,
  AiOutlineFund,
  AiOutlineArrowDown,
} from 'react-icons/ai';
import {
  FaLinkedinIn,
  FaFacebookF,
  FaUserAlt,
  FaQuoteRight,
  FaPhone,
  FaRegAddressCard,
  FaFileInvoice,
  FaRegStickyNote,
  FaEllipsisV,
  FaCog,
  FaTimes,
  FaAsterisk,
  FaRegUserCircle,
  FaRegComment,
} from 'react-icons/fa';
import {
  BsCheckCircle,
  BsBookmark,
  BsToggleOn,
  BsToggleOff,
  BsShield,
  BsTrash,
  BsChevronRight,
  BsChevronLeft,
} from 'react-icons/bs';
import { BiUpvote } from 'react-icons/bi';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ImFileVideo } from 'react-icons/im';

const WrapperBackgroundStyles = ({ background }) => {
  if (background === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (background === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[1]}, 1)`};
    `;
  } else if (background === 'primary') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
    `;
  } else if (background === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
    `;
  } else if (background === 'lightGrey') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[4]}, 1)`};
    `;
  } else if (background === 'darkOverlay') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[2]}, 0.7)`};
    `;
  } else if (background === 'success') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
    `;
  } else if (background === 'danger') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
    `;
  } else {
    return css`
      background-color: none;
    `;
  }
};

const IconColorStyles = ({ color }) => {
  if (color === 'light') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  } else if (color === 'darkOverlay') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else if (color === 'primary') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
    `;
  } else if (color === 'linkBlue') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
    `;
  } else if (color === 'alt') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
    `;
  } else if (color === 'lightGrey') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    `;
  } else if (color === 'midGrey') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
    `;
  } else if (color === 'success') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
    `;
  } else if (color === 'danger') {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
    `;
  } else {
    return css`
      color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    `;
  }
};

const IconWrapper = styled.div`
  border-radius: ${(props) =>
    props.isCircle ? '50%' : props.theme.styles.borderRadius};
  font-size: ${(props) => (props.size ? `${props.size}rem` : '1rem')};
  padding: ${(props) => (props.background ? '0.5rem 0.5rem 0 0.5rem;' : '0')};
  padding-bottom: ${(props) => (props.isCircle ? '0.1rem' : '')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  display: flex;
  ${WrapperBackgroundStyles}
  > * {
    ${IconColorStyles}
    align-self: center;
  }
`;

const Icon = ({ iconType, size, color, background, isCircle, margin }) => {
  return (
    <IconWrapper
      background={background}
      size={size}
      color={color}
      isCircle={isCircle}
      margin={margin}
    >
      {iconType === 'twitter' && <AiOutlineTwitter />}
      {iconType === 'facebook' && <FaFacebookF />}
      {iconType === 'linkedin' && <FaLinkedinIn />}
      {iconType === 'mail' && <AiOutlineMail />}
      {iconType === 'search' && <AiOutlineSearch />}
      {iconType === 'upArrow' && <AiOutlineArrowUp />}
      {iconType === 'downArrow' && <AiOutlineArrowDown />}{' '}
      {iconType === 'videocamera' && <AiOutlineVideoCamera />}
      {iconType === 'clock' && <AiOutlineClockCircle />}
      {iconType === 'user' && <FaUserAlt />}{' '}
      {iconType === 'comment' && <FaRegComment />}
      {iconType === 'quote' && <FaQuoteRight />}
      {iconType === 'phone' && <FaPhone />}
      {iconType === 'add' && <AiOutlineUserAdd />}
      {iconType === 'close' && <AiOutlineClose />}
      {iconType === 'downcaret' && <AiOutlineCaretDown />}
      {iconType === 'bulletpoint' && <AiOutlineRightCircle />}
      {iconType === 'dashboard' && <AiOutlineDashboard />}
      {iconType === 'userlist' && <AiOutlineUsergroupAdd />}
      {iconType === 'tasklist' && <AiOutlineOrderedList />}
      {iconType === 'profile' && <AiOutlineLock />}
      {iconType === 'calendar' && <AiOutlineCalendar />}
      {iconType === 'addresscard' && <FaRegAddressCard />}
      {iconType === 'fileupload' && <AiOutlineFileText />}
      {iconType === 'fileinvoice' && <FaFileInvoice />}
      {iconType === 'folderopen' && <AiOutlineFolderOpen />}
      {iconType === 'logout' && <AiOutlineLogin />}
      {iconType === 'exclamationTriangle' && <AiOutlineExclamationCircle />}
      {iconType === 'checkCircle' && <BsCheckCircle />}
      {iconType === 'stickyNote' && <FaRegStickyNote />}
      {iconType === 'calculator' && <AiOutlineCalculator />}
      {iconType === 'bookmark' && <BsBookmark />}
      {iconType === 'ellipsisV' && <FaEllipsisV />}
      {iconType === 'toggleOff' && <BsToggleOff />}
      {iconType === 'toggleOn' && <BsToggleOn />}{' '}
      {iconType === 'plusfill' && <AiFillPlusCircle />}
      {iconType === 'infoCircle' && <AiOutlineInfoCircle />}
      {iconType === 'history' && <AiOutlineHistory />}
      {iconType === 'filePdf' && <AiOutlineFilePdf />}
      {iconType === 'fileImage' && <AiOutlineFileImage />}
      {iconType === 'cog' && <FaCog />}
      {iconType === 'times' && <FaTimes />}
      {iconType === 'settings' && <AiOutlineSetting />}
      {iconType === 'reconcile' && <AiOutlineReconciliation />}
      {iconType === 'solution' && <AiOutlineSolution />}
      {iconType === 'hdd' && <AiOutlineHdd />}
      {iconType === 'global' && <AiOutlineGlobal />}
      {iconType === 'asterisk' && <FaAsterisk />}
      {iconType === 'copy' && <AiOutlineCopy />}
      {iconType === 'copied' && <AiFillCopy />}
      {iconType === 'next' && <AiOutlineArrowRight />}
      {iconType === 'back' && <AiOutlineArrowLeft />}
      {iconType === 'trash' && <BsTrash />}
      {iconType === 'plus' && <IoIosAddCircleOutline />}
      {iconType === 'file-done' && <AiOutlineFileDone />}
      {iconType === 'connections' && <AiOutlineCluster />}
      {iconType === 'shop' && <AiOutlineShop />}
      {iconType === 'income' && <AiOutlineAppstoreAdd />}
      {iconType === 'pie-chart' && <AiOutlinePieChart />}
      {iconType === 'line-with-outline' && <AiOutlineFund />}
      {iconType === 'one-one' && <AiOutlineOneToOne />}
      {iconType === 'shield' && <BsShield />}
      {iconType === 'videofile' && <ImFileVideo />}
      {iconType === 'userCircle' && <FaRegUserCircle />}
      {iconType === 'menu' && <AiOutlineMenu />}
      {iconType === 'comments' && <AiOutlineComment />}
      {iconType === 'play' && <AiOutlinePlayCircle />}
      {iconType === 'stop' && <AiOutlineStop />}
      {iconType === 'playSquare' && <AiOutlinePlaySquare />}
      {iconType === 'lightbulb' && <AiOutlineBulb />}
      {iconType === 'fileProtected' && <AiOutlineFileProtect />}
      {iconType === 'chevron-right' && <BsChevronRight />}
      {iconType === 'chevron-left' && <BsChevronLeft />}
      {iconType === 'youtube' && <AiFillYoutube />}
      {iconType === 'upvote' && <BiUpvote />}
    </IconWrapper>
  );
};
//impelment the icons instead of iconclass
Icon.propTypes = {
  margin: PropTypes.string,
  iconType: PropTypes.oneOf([
    'chevron-right',
    'upvote',
    'chevron-left',
    'income',
    'youtube',
    'comments',
    'comment',
    'lightbulb',
    'fileProtected',
    'menu',
    'pie-chart',
    'twitter',
    'facebook',
    'linkedin',
    'mail',
    'search',
    'upArrow',
    'downArrow',
    'user',
    'clock',
    'quote',
    'phone',
    'add',
    'close',
    'playSquare',
    'bulletpoint',
    'downcaret',
    'dashboard',
    'userlist',
    'tasklist',
    'play',
    'stop',
    'profile',
    'calendar',
    'addresscard',
    'fileupload',
    'fileinvoice',
    'folderopen',
    'logout',
    'exclamationTriangle',
    'checkCircle',
    'asterisk',
    'stickyNote',
    'calculator',
    'bookmark',
    'shield',
    'ellipsisV',
    'toggleOff',
    'toggleOn',
    'infoCircle',
    'history',
    'filePdf',
    'fileImage',
    'cog',
    'times',
    'settings',
    'reconcile',
    'solution',
    'hdd',
    'global',
    'copy',
    'copied',
    'next',
    'plusfill',
    'back',
    'trash',
    'plus',
    'file-done',
    'videocamera',
    'connections',
    'shop',
    'line-with-outline',
    'videofile',
    'one-one',
    'userCircle',
  ]),
  size: PropTypes.number.isRequired,
  isCircle: PropTypes.bool,
  color: PropTypes.oneOf([
    'light',
    'dark',
    'primary',
    'alt',
    'lightGrey',
    'midGrey',
    'success',
    'danger',
    'linkBlue',
  ]),
  background: PropTypes.oneOf([
    'light',
    'dark',
    'primary',
    'alt',
    'lightGrey',
    'darkOverlay',
  ]),
};

export default Icon;
