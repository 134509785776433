import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const colorStyles = ({ color }) => {
  if (color === 'dark') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'alt') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.secondary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'light') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'light-grey') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[3]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: none;
    `;
  } else if (color === 'success') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.success[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else if (color === 'outline-light') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
    `;
  } else if (color === 'outline-dark') {
    return css`
      background-color: none;
      color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
      border: 1px solid
        ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    `;
  } else if (color === 'danger') {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.danger[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.primary[0]}, 1)`};
      color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
      border: none;
    `;
  }
};
const Container = styled.div`
  margin: ${(props) => props.margin};
`;

const Wrapper = styled.div`
  display: flex;
  width: 60%;
`;
const Item = styled.div`
  ${colorStyles}
  height: 7px;
  margin-right: 2%;
  width: ${(props) => `${props.width}%`};
`;

const Sub = styled.div`
  padding-bottom: 0.25rem;
`;

const Title = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const ProgressBar = ({ total, current, margin, title }) => {
  const progress = [];
  const itemWidth = (100 - total * 3) / total;
  let i;
  for (i = 1; i <= total; i++) {
    if (i < current) {
      progress.push('success');
    } else if (i === current) {
      progress.push('primary');
    } else {
      progress.push('light-grey');
    }
  }
  return (
    <Container margin={margin}>
      <Sub>
        <Title>{title}</Title>
        {current} of {total}
      </Sub>
      <Wrapper>
        {progress.map((item, index) => (
          <Item color={item} width={itemWidth} key={index} />
        ))}
      </Wrapper>
    </Container>
  );
};

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  margin: PropTypes.string,
  title: PropTypes.string,
};

export default ProgressBar;
