import React, { useContext, useEffect } from 'react';

import styled from 'styled-components';
import AuthContext from '../../context/auth/authContext';
import Icon from '../UI/Icon';

import { Button } from '../Controls';

const Container = styled.div`
  margin: auto;
  padding: 1rem 0;
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const Label = styled.span`
  color: ${(props) => `rgba(${props.theme.colors.tertiary[4]}, 1)`};
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
`;

const ProfileImgWrapper = styled.div`
  width: 12rem;
  height: 12rem;
  background: ${(props) => `rgba(${props.theme.colors.primary[1]}, 0.3)`};
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 2rem;
`;

const Account = (props) => {
  const authContext = useContext(AuthContext);
  const { logout, userData, loadUser } = authContext;
  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const onLogout = () => {
    props.history.push('/login');

    logout();
  };

  return (
    <Container>
      <h2 className='text-center py-2'>Profile Details</h2>
      <ProfileImgWrapper>
        <Icon iconType='user' size={5} margin='auto' color='light' />
      </ProfileImgWrapper>
      <div className='text-center'>
        <div>
          <div className='py'>
            <Label>Name: </Label>
            {userData && userData.name}
          </div>
          <div className='py'>
            <Label>Email: </Label>
            {userData && userData.email}
          </div>
        </div>
        <div className='pt-2'>
          <Button
            onClick={onLogout}
            block={'true'}
            color={'success'}
            fontSize={1}
          >
            logout
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Account;
