import React, { useReducer } from 'react';
import { db } from '../firebase';
import { firestore } from 'firebase/app';
import moment from 'moment';
import SeminarsContext from './seminarsContext';
import seminarsReducer from './seminarsReducer';
import {
  GET_SEMINARS,
  SET_CURRENT_SEMINAR,
  CLEAR_CURRENT_SEMINAR,
  FILTER_SEMINARS,
  CLEAR_SEMINAR_FILTER,
  SEMINAR_ERROR,
  CLEAR_SEMINARS,
  UPDATE_SEMINAR,
} from '../types';

const SeminarsState = (props) => {
  const initalState = {
    seminars: null,
    loading: true,
    current: null,
    filtered: null,
    error: null,
  };

  const [state, dispatch] = useReducer(seminarsReducer, initalState);

  // Get seminars
  const getSeminars = async () => {
    const seminarList = [];
    db.collection('seminars')
      .orderBy('startTime')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const seminarItem = { ...doc.data() };

          if (!seminarItem.deleted) {
            seminarItem.id = doc.id;
            //do validation checking here
            if (seminarItem.startTime) {
              console.log(seminarItem.startTime.seconds);
              seminarItem.startTime =
                !!seminarItem.startTime.seconds &&
                seminarItem.startTime.toDate();
              seminarList.push(seminarItem);
            }
          }
        });
        const mutatedData = {
          all: seminarList,
          past: [],
          future: [],
        };
        const dateToCheck = moment(new Date()).add(-240, 'm').toDate();
        seminarList.forEach((seminar) => {
          seminar.startTime < dateToCheck
            ? mutatedData.past.unshift(seminar)
            : mutatedData.future.push(seminar);
        });
        mutatedData.next = mutatedData.future[0];
        dispatch({
          type: GET_SEMINARS,
          payload: mutatedData,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SEMINAR_ERROR,
          payload: err,
        });
      });
  };

  // Get Seminar
  const getSeminar = async (seminarID) => {
    const docRef = db.collection('seminars').doc(seminarID);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({ type: SET_CURRENT_SEMINAR, payload: doc.data() });
        } else {
          dispatch({
            type: SEMINAR_ERROR,
            payload: 'no such document',
          });
        }
      })
      .catch(function (err) {
        dispatch({
          type: SEMINAR_ERROR,
          payload: err,
        });
      });
  };

  // Update Seminar
  const updateSeminar = async (
    seminar,
    authUserID,
    onDemandRegister,
    seminars
  ) => {
    if (!!onDemandRegister) {
      db.collection('seminars')
        .doc(seminar.id)
        .update({
          onDemandRegisteredUsers: firestore.FieldValue.arrayUnion(authUserID),
        })
        .then(() => {
          const update = { ...seminar };
          !!seminar.onDemandRegisteredUsers
            ? update.onDemandRegisteredUsers.push(authUserID)
            : (update.onDemandRegisteredUsers = [authUserID]);
          const updatedSeminars = UpdateSeminarsRecord(update, seminars);
          dispatch({
            type: UPDATE_SEMINAR,
            payload: { current: update, seminars: updatedSeminars },
          });
        })
        .catch((err) => {
          dispatch({
            type: SEMINAR_ERROR,
            payload: err,
          });
        });
    } else {
      db.collection('seminars')
        .doc(seminar.id)
        .update({
          registeredUsers: firestore.FieldValue.arrayUnion(authUserID),
        })
        .then(() => {
          const update = { ...seminar };
          !!seminar.registeredUsers
            ? update.registeredUsers.push(authUserID)
            : (update.registeredUsers = [authUserID]);
          const updatedSeminars = UpdateSeminarsRecord(update, seminars);
          dispatch({
            type: UPDATE_SEMINAR,
            payload: { current: update, seminars: updatedSeminars },
          });
        })
        .catch((err) => {
          dispatch({
            type: SEMINAR_ERROR,
            payload: err,
          });
        });
    }
  };

  //Update Seminars Records
  const UpdateSeminarsRecord = (update, seminars) => {
    const updatedSeminars = { ...seminars };
    updatedSeminars.all.forEach((seminar, index) => {
      seminar.id === update.id && (updatedSeminars.all[index] = update);
    });
    updatedSeminars.past.forEach((seminar, index) => {
      seminar.id === update.id && (updatedSeminars.past[index] = update);
    });
    updatedSeminars.future.forEach((seminar, index) => {
      seminar.id === update.id && (updatedSeminars.future[index] = update);
    });
    updatedSeminars.next.id === update.id && (updatedSeminars.next = update);

    return updatedSeminars;
  };

  // Clear Seminar
  const clearSeminars = () => {
    dispatch({ type: CLEAR_SEMINARS });
  };

  // Set Current Seminar
  const setCurrent = (Seminar) => {
    dispatch({ type: SET_CURRENT_SEMINAR, payload: Seminar });
  };

  // Clear Current Seminar
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT_SEMINAR });
  };

  // Filter Seminar
  const filterSeminars = (text) => {
    dispatch({ type: FILTER_SEMINARS, payload: text });
  };

  // Clear Filters
  const clearFilter = () => {
    dispatch({ type: CLEAR_SEMINAR_FILTER });
  };

  return (
    <SeminarsContext.Provider
      value={{
        seminars: state.seminars,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        loading: state.loading,
        setCurrent,
        clearCurrent,
        filterSeminars,
        clearFilter,
        getSeminars,
        clearSeminars,
        getSeminar,
        updateSeminar,
      }}
    >
      {props.children}
    </SeminarsContext.Provider>
  );
};

export default SeminarsState;
