import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const colorStyles = ({ dark }) => {
  if (dark) {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[3]}, 1)`};
    `;
  } else {
    return css`
      background-color: ${(props) =>
        `rgba(${props.theme.colors.neutral[5]}, 1)`};
    `;
  }
};

const Loader = styled.div`
  width: 10px;
  margin: 5px;
  height: 30px;
  -webkit-animation: pulse 0.7s linear infinite; /* Safari */
  animation: pulse 0.7s linear infinite;
  ${colorStyles};
  /* Safari */
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scaleY(1);
    }
    60% {
      -webkit-transform: scaleY(2);
    }

    100% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scaleY(1);
    }
    60% {
      transform: scaleY(2);
    }

    100% {
      transform: scaleY(1);
    }
  }
`;

const RightLoader = styled(Loader)`
  animation-delay: -0.16s;
`;

const LeftLoader = styled(Loader)`
  animation-delay: -0.32s;
`;

const LoadingSpinner = ({ dark }) => {
  return (
    <Wrapper>
      <Loader dark />
      <RightLoader dark /> <LeftLoader dark />
    </Wrapper>
  );
};

export default LoadingSpinner;
