import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
    font-family: ${(props) => props.theme.fonts.mainFontFamily};
  }

  a {
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
    text-decoration: none;
  }

  a:hover {
  opacity: ${(props) => props.theme.styles.onHoverOpacity};
  }

ul {
  list-style: none;
}


h1 {

  font-weight: 400;
}
h2 {
 
  font-weight: 400;
}
h3 {
 
  font-weight: 400;
}
h4 {

  font-weight: 400;
}
h5 {
 
  font-weight: 400;
}
h6 {

  font-weight: 400;
}


/* Utilities */

.dash {
  width: 2rem;
  height: 3px;
  margin: 1rem 0;
  background-color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
}

.container {
  max-width: ${(props) => props.theme.styles.mdBreakPoint};
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

.clickable:hover {
      cursor: pointer;
      opacity: ${(props) => props.theme.styles.onHoverOpacity};
}

.hidden {
  display: none
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 4.7rem;
  margin-bottom: 1rem;
}

.large {
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

.lead {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: 2.2rem;
}

.small {
    font-size: 0.7rem;
  }

.text-primary {
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
}
.text-success {
  color: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
}
.text-danger {
  color: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
}

.text-light {
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
}

.text-dark {
    color: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
}

.text-alt {
    color: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
}

.bg-primary {
  background: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
}
.bg-success {
  background: ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
}
.bg-danger {
  background: ${(props) => `rgba(${props.theme.colors.danger[0]}, 1)`};
}
.bg-light-success {
  background: ${(props) => `rgba(${props.theme.colors.success[0]}, 0.3)`};
}
.bg-dark {
    background: ${(props) => `rgba(${props.theme.colors.neutral[0]}, 1)`};
}
.bg-medium {
    background: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 0.8)`};
}
.bg-alt {
    background: ${(props) => `rgba(${props.theme.colors.secondary[0]}, 1)`};
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}


/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

.py-10 {
  padding: 10rem 0;
}

.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-6 {
  padding-top: 6rem;
}
.pb-half {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-6 {
  padding-bottom: 6rem;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}


.mh-1 {
  margin: 0 1rem ;
}
.mh-2 {
  margin: 0 2rem ;
}
.mh-3 {
  margin: 0 3rem ;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}

/* Grid */
.grid-2,
.grid-2-md {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
}


/* Mobile Styles */


.show-md {
  display: none;
}

@media (max-width: ${(props) => props.theme.styles.mdBreakPoint}) {
  .grid-2-md {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .hide-md {
    display: none;
  }
  .show-md {
    display: inline;
  }
}

@media (max-width: ${(props) => props.theme.styles.smBreakPoint}) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-2-md .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }
  
  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;   
  }

  .lead {
    font-size: 1rem;
  }
  .small {
    font-size: 0.7rem;
  }
}
`;

export default GlobalStyles;
