import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import AuthContext from '../../context/auth/authContext';
import SeminarsContext from '../../context/seminars/seminarsContext';

import LineBreak from '../UI/LineBreak';
import Icon from '../UI/Icon';

const Wrapper = styled.div`
  padding: 1rem;
  max-width: 600px;
  padding-top: 2rem;
`;

const DoneLine = styled.div`
  text-align: right;
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding: 2rem 0 1rem 0;
`;

const SeminarName = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
`;

const SeminarDate = styled.div`
  font-size: 0.7rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
`;

const Main = styled.div`
  margin-top: 1rem;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  border-radius: 5px;
  background: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  padding: 0.5rem;
  margin-right: 0.5rem;
`;

const SubText = styled.div`
  font-size: 0.9rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
`;
const Toggle = styled.div`
  margin-left: 0.5rem;
`;

const FlexGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
`;

const SeminarRegister = () => {
  const history = useHistory();

  const authContext = useContext(AuthContext);

  const seminarsContext = useContext(SeminarsContext);
  const [seminarData, setSeminarData] = useState();

  useEffect(() => {
    if (!!seminarsContext.current) {
      setSeminarData(seminarsContext.current);
    } else {
      history.push('/');
    }
  }, [seminarsContext, history]);

  const handleRegisterToggle = () => {
    seminarsContext.updateSeminar(
      seminarData,
      authContext.user.uid,
      false,
      seminarsContext.seminars
    );
  };

  const handleOnDemandToggle = () => {
    seminarsContext.updateSeminar(
      seminarData,
      authContext.user.uid,
      true,
      seminarsContext.seminars
    );
  };

  return (
    <Wrapper>
      {!!seminarsContext.current && !!seminarData && !!seminarData.title && (
        <>
          <DoneLine onClick={() => history.push('/seminars/details')}>
            Done
          </DoneLine>
          <Title>Register</Title>
          <SeminarName>{seminarData.title}</SeminarName>
          <SeminarDate>
            {moment(seminarData.startTime).format('ddd Do MMMM YYYY h:mm a')}
          </SeminarDate>
          <Main>
            <LineItem>
              <IconWrapper>
                <Icon iconType='videocamera' size={1} color='light' />
              </IconWrapper>
              <FlexGroup>
                <p>Attend Live</p>
                <SubText>
                  Register to access the seminar call details and attend at the
                  stated start time
                </SubText>
              </FlexGroup>
              {!!seminarData.registeredUsers &&
              seminarData.registeredUsers.includes(authContext.user.uid) ? (
                <Toggle>
                  <Icon iconType='toggleOn' size={2.5} color='success' />
                </Toggle>
              ) : (
                <Toggle
                  onClick={() => {
                    handleRegisterToggle();
                  }}
                >
                  <Icon iconType='toggleOff' size={2.5} color='midGrey' />
                </Toggle>
              )}
            </LineItem>
            <LineBreak />
            <LineItem>
              <IconWrapper>
                <Icon iconType='videofile' size={1} color='light' />
              </IconWrapper>
              <FlexGroup>
                <p>Watch On-Demand</p>
                <SubText>
                  Get notified when the seminar recording and presentation is
                  made available shortly after the event.
                </SubText>
              </FlexGroup>
              {!!seminarData.onDemandRegisteredUsers &&
              seminarData.onDemandRegisteredUsers.includes(
                authContext.user.uid
              ) ? (
                <Toggle>
                  <Icon iconType='toggleOn' size={2.5} color='success' />
                </Toggle>
              ) : (
                <Toggle
                  onClick={() => {
                    handleOnDemandToggle();
                  }}
                >
                  <Icon iconType='toggleOff' size={2.5} color='midGrey' />
                </Toggle>
              )}
            </LineItem>
            <LineBreak />
          </Main>
        </>
      )}
    </Wrapper>
  );
};

export default SeminarRegister;
