import React from 'react';
import styled from 'styled-components';
//import Logo from '../../assets/logo.png';

import { Link, useLocation } from 'react-router-dom';

import Icon from '../UI/Icon';

const Wrapper = styled.div`
  width: 100vw;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border-top: ${(props) =>
    `1px solid rgba(${props.theme.colors.neutral[3]}, 0.4)`};

  @media only screen and (min-width: 800px) {
    position: fixed;
    bottom: calc(100% - 5rem);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`;

const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (min-width: 800px) {
    padding: 0.25rem 1rem;
  }
`;
/*
const LogoWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: none;
  @media only screen and (min-width: 800px) {
    display: inline;
  }
`;
const StyledLogo = styled.img`
  height: 50px;
`;*/
const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 0 1rem 0;
  @media only screen and (min-width: 800px) {
    justify-content: flex-end;
    padding: 0.5rem 0;
  }
`;

const IconWrapper = styled.div`
  color: ${(props) =>
    props.active
      ? `rgba(${props.theme.colors.linkBlue[0]}, 1)`
      : `rgba(${props.theme.colors.neutral[3]}, 1)`};
  display: flex;
  text-transform: capitalize;
  font-size: 0.6rem;
  flex-direction: column;
  text-align: center;
  @media only screen and (min-width: 800px) {
    padding: 0 1rem;
  }
`;

const Header = () => {
  const location = useLocation();

  const hideIfRoutes = ['/seminars/details'];

  return (
    <>
      {!hideIfRoutes.includes(location.pathname) && (
        <Wrapper>
          <Container>
            <MenuWrapper>
              <Link to='/seminars/recent'>
                <IconWrapper active={location.pathname === '/seminars/recent'}>
                  <Icon
                    iconType='tasklist'
                    size={1.7}
                    color={
                      location.pathname === '/seminars/recent'
                        ? 'linkBlue'
                        : 'midGrey'
                    }
                    margin='auto'
                  />
                  recent
                </IconWrapper>
              </Link>
              <Link to='/'>
                <IconWrapper active={location.pathname === '/'}>
                  <Icon
                    iconType='one-one'
                    size={1.7}
                    margin='auto'
                    color={location.pathname === '/' ? 'linkBlue' : 'midGrey'}
                  />
                  Home
                </IconWrapper>
              </Link>
              <Link to='/profile'>
                <IconWrapper active={location.pathname === '/profile'}>
                  <Icon
                    iconType='userCircle'
                    size={1.7}
                    color={
                      location.pathname === '/profile' ? 'linkBlue' : 'midGrey'
                    }
                    margin='auto'
                  />
                  Profile
                </IconWrapper>
              </Link>
            </MenuWrapper>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default Header;
