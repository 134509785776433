import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';

import { Button } from '../Controls';
import Alerts from '../Feedback/Alerts';

import Logo from '../../assets/logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  margin: 0;
  margin: auto;
  padding: 1rem;
  max-height: 800px;
`;

const StyledLogo = styled.img`
  margin: auto;
  width: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
`;

const LabelGroup = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  }
`;

const StyledInput = styled.input`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  display: block;
  width: 100%;
  margin: 1rem 0 2rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border: 3px solid ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border-radius: 2px;
  &:focus,
  &:active {
    outline: none;
    border: 3px solid ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
  }
`;

const FooterLink = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.tertiary[0]}, 1)`};
  padding-top: 1rem;
`;

const Underlined = styled.span`
  text-decoration: underline;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.7)`};
`;

const PasswordReset = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const {
    error,
    clearErrors,
    isAuthenticated,
    loadUser,
    passwordReset,
  } = authContext;

  useEffect(() => {
    loadUser();
    if (isAuthenticated) {
      props.history.push('/');
    }

    if (!!error && error.code === 'password-reset-success') {
      setAlert(error.message, 'success-mail');
      clearErrors();
    } else if (!!error) {
      console.log(error);
      setAlert('Password reset error', 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
  });

  const { email } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      setAlert('Please provide your email address', 'danger');
    } else {
      passwordReset(email);
      setRequested(true);
    }
  };

  const [requested, setRequested] = useState(false);

  return (
    <Wrapper>
      <StyledLogo alt='logo' src={Logo} />
      <Alerts />
      <form onSubmit={onSubmit}>
        <div>
          <LabelGroup>
            <label htmlFor='email'>Email Address</label>
          </LabelGroup>

          <StyledInput
            type='email'
            name='email'
            placeholder='e.g. obama@email.com'
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <Button
          onClick={onSubmit}
          block={'true'}
          color={!!requested ? 'dark' : 'primary'}
          fontSize={1.2}
          disabled={requested}
        >
          {!requested ? 'Reset Password' : 'Requested'}
        </Button>
        <FooterLink>
          Don't have an account?{' '}
          <Link to='/register'>
            <Underlined>Sign up</Underlined>
          </Link>
        </FooterLink>
      </form>
    </Wrapper>
  );
};

export default PasswordReset;
