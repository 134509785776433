import React, { useContext, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import moment from 'moment';

import SeminarsContext from '../../context/seminars/seminarsContext';
import LineBreak from '../UI/LineBreak';
import Pending from '../../assets/pending.jpg';

import { Button } from '../Controls';

const Tile = styled.div`
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
`;

const TileImg = styled.div`
  border-radius: 10px;
  height: 80px;
  width: 80px;
  margin-right: 0.5rem;
  min-height: 80px;
  min-width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
      0deg, rgba(${props.theme.colors.neutral[0]}, 0), rgba(${props.theme.colors.neutral[0]}, 0)), url(${props.img}) `};
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const Title = styled.div`
  padding-bottom: 0.25rem;
  font-size: 1rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
  font-weight: bold;
`;

const Description = styled.div`
  padding-bottom: 0.25rem;
  font-size: 0.9rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
`;

const Date = styled.div`
  padding-bottom: 0.25rem;
  font-size: 0.8rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 0.8)`};
`;

const SeminarList = ({ seminars, reverse, includeRegisterButton }) => {
  const history = useHistory();

  const seminarsContext = useContext(SeminarsContext);

  const handleSeminarSelect = (seminar) => {
    seminarsContext.setCurrent(seminar);
    history.push('/seminars/details');
  };

  const mutatedSeminars = !!reverse ? [seminars[1]] : seminars;

  return (
    <>
      {!!mutatedSeminars && !!mutatedSeminars.length && (
        <>
          {mutatedSeminars.map((seminar, index) => (
            <Fragment key={seminar.id}>
              <Tile onClick={() => handleSeminarSelect(seminar)}>
                {seminar.thumbnailImgUrl ? (
                  <TileImg img={seminar.thumbnailImgUrl} />
                ) : (
                  <TileImg img={Pending} />
                )}
                <TextGroup>
                  <Date>
                    {moment(seminar.startTime).format(
                      'ddd Do MMMM YYYY, h:mm a'
                    )}
                  </Date>
                  <Title>{seminar.title}</Title>
                  {seminar.description && (
                    <Description>
                      {seminar.description.substring(0, 70)}...
                    </Description>
                  )}
                  {!!includeRegisterButton && (
                    <Button size='small' color='light' bold='true'>
                      Register
                    </Button>
                  )}
                </TextGroup>
              </Tile>
              <LineBreak />
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default SeminarList;
