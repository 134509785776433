import React from 'react';
import styled from 'styled-components';
import AppNav from './AppNav';

const StyledAppLayout = styled.div`
  width: 100vw;
  overflow-x: hidden;
`;

const AppContent = styled.div`
  max-width: 1100px;
  margin: auto;
  margin-bottom: 3rem;
  @media only screen and (min-width: 800px) {
    margin-top: 5rem;
  }
`;

const AppLayout = ({ children }) => {
  return (
    <StyledAppLayout>
      <AppNav />
      <AppContent>{children}</AppContent>
    </StyledAppLayout>
  );
};

export default AppLayout;
