import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = styled.div`
  width: 30px;
  margin: 5px;
  height: 90px;
  -webkit-animation: pulse 0.7s linear infinite; /* Safari */
  animation: pulse 0.7s linear infinite;
  background-color: darkgray;
  /* Safari */
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scaleY(1);
    }
    60% {
      -webkit-transform: scaleY(2);
    }

    100% {
      -webkit-transform: scaleY(1);
    }
  }

  @keyframes pulse {
    0% {
      transform: scaleY(1);
    }
    60% {
      transform: scaleY(2);
    }

    100% {
      transform: scaleY(1);
    }
  }
`;

const RightLoader = styled(Loader)`
  animation-delay: -0.16s;
`;

const LeftLoader = styled(Loader)`
  animation-delay: -0.32s;
`;

const PageLoader = () => {
  return (
    <Backdrop>
      <Wrapper>
        <Loader />
        <RightLoader /> <LeftLoader />
      </Wrapper>
    </Backdrop>
  );
};

export default PageLoader;
