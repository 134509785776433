import {
  GET_SEMINARS,
  SET_CURRENT_SEMINAR,
  CLEAR_CURRENT_SEMINAR,
  FILTER_SEMINARS,
  CLEAR_SEMINAR_FILTER,
  SEMINAR_ERROR,
  CLEAR_SEMINARS,
  UPDATE_SEMINAR,
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_SEMINARS:
      return {
        ...state,
        seminars: action.payload,
        loading: false,
      };
    case UPDATE_SEMINAR:
      return {
        ...state,
        seminars: action.payload.seminars,
        current: action.payload.current,
        loading: false,
      };
    case CLEAR_SEMINARS:
      return {
        ...state,
        seminars: null,
        filtered: null,
        error: null,
        current: null,
      };
    case SET_CURRENT_SEMINAR:
      return {
        ...state,
        current: action.payload,
      };
    case CLEAR_CURRENT_SEMINAR:
      return {
        ...state,
        current: null,
      };
    case FILTER_SEMINARS:
      return {
        ...state,
        filtered: state.seminars.filter((seminar) => {
          if (action.payload === '*') {
            return '';
          } else {
            const regex = new RegExp(`${action.payload}`, 'gi');
            return seminar.title.match(regex);
          }
        }),
      };
    case CLEAR_SEMINAR_FILTER:
      return {
        ...state,
        filtered: null,
      };
    case SEMINAR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
