import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import SeminarsContext from '../../context/seminars/seminarsContext';

import LoadingSpinner from '../Feedback/LoadingSpinner';
import SeminarList from '../Seminar/SeminarList';

const CenteredSpinner = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 1rem;
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0;
`;

const SubTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem 0;
`;

const All = () => {
  const seminarsContext = useContext(SeminarsContext);
  useEffect(() => {
    if (!seminarsContext.seminars) {
      seminarsContext.getSeminars();
    } else {
      seminarsContext.setCurrent(seminarsContext.seminars.next);
    }
    // eslint-disable-next-line
  }, [seminarsContext.seminars]);

  return (
    <Container>
      {!!seminarsContext.current ? (
        <>
          <Title>Seminars</Title>

          {seminarsContext.seminars.future &&
            seminarsContext.seminars.future.length > 0 && (
              <>
                <FlexGroup>
                  <SubTitle>Upcoming Seminars</SubTitle>
                </FlexGroup>
                <SeminarList seminars={seminarsContext.seminars.future} />
              </>
            )}
          {seminarsContext.seminars.past &&
            seminarsContext.seminars.past.length > 0 && (
              <>
                <FlexGroup>
                  <SubTitle>Recent Seminars</SubTitle>
                </FlexGroup>
                <SeminarList seminars={seminarsContext.seminars.past} />
              </>
            )}
        </>
      ) : (
        <CenteredSpinner>
          <LoadingSpinner />
        </CenteredSpinner>
      )}
    </Container>
  );
};

export default All;
