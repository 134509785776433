export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const AUTH_LOADING = 'AUTH_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';

export const GET_SEMINARS = 'GET_SEMINARS';
export const UPDATE_SEMINAR = 'UPDATE_SEMINAR';
export const SET_CURRENT_SEMINAR = 'SET_CURRENT_SEMINAR';
export const CLEAR_CURRENT_SEMINAR = 'CLEAR_CURRENT_SEMINAR';
export const FILTER_SEMINARS = 'FILTER_SEMINARS';
export const CLEAR_SEMINAR_FILTER = 'CLEAR_SEMINAR_FILTER';
export const SEMINAR_ERROR = 'SEMINAR_ERROR';
export const CLEAR_SEMINARS = 'CLEAR_SEMINARS';
