import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';

import { Button } from '../Controls';
import Alerts from '../Feedback/Alerts';

//import Logo from '../../assets/logo-light.png';
//import Truinvest from '../../assets/poweredbylight.png';
import BG from '../../assets/bg.png';
import PageLoader from '../Feedback/PageLoader';

import Icon from '../UI/Icon';

const Container = styled.div`
  width: 100vw;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
      125deg, rgba(${props.theme.colors.primary[0]}, 0.97), rgba(${props.theme.colors.neutral[0]}, 0.85)), url(${BG}) `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100vw;
  max-width: 600px;
  margin: 0;
  margin: auto;
  padding: 1rem;
  max-height: 800px;
`;

/*const StyledLogo = styled.img`
  margin: auto;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 0;
`;
*/
const Title = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  font-size: 2.5rem;
  text-align: center;
  padding: 3rem 0 2rem 0;
  font-weight: 300;
`;

const StyledInput = styled.input`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 0.4)`};
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]},1)`};
  outline: none;
  border: none;
  border-radius: 10px;
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`;

const FooterLink = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding-top: 1rem;
  text-decoration: none;
  text-align: center;
`;

const Underlined = styled.span`
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 0.7)`};
`;

const StyledFooter = styled.footer`
  padding-top: 4rem;
`;

const Socials = styled.div`
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  padding: 0.5rem;
  max-width: 50vw;
  margin: auto;
  align-items: center;
`;

const OutsideLinks = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  padding: 0.5rem;
  > a {
    padding: 0 0.5rem;
    color: white;
    font-size: small;
  }
`;

const ForgotPassword = styled.div`
  text-align: right;
  margin: 0.5rem 0 2rem 0;
`;
/*
const PoweredByGrp = styled.a`
  text-align: center;
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 0.7)`};
  font-weight: 300;
  text-transform: uppercase;
  padding: 2rem 0;
`;

const PoweredByLogo = styled.img`
  margin: auto;
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.25rem;
`;
*/
const Login = (props) => {
  const [customLoading, setCustomLoading] = useState(false);

  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated, loadUser, loading } =
    authContext;

  useEffect(() => {
    loadUser();

    if (isAuthenticated) {
      setCustomLoading(true);
      setTimeout(() => {
        props.history.push('/');
      }, 500);
    }

    if (!!error && error.code === 'auth/wrong-password') {
      setAlert('Invalid email address or password', 'danger');
      clearErrors();
    } else if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const { email, password } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      login({ email, password });
    }
  };
  return (
    <Container>
      {!customLoading ? (
        <Wrapper>
          <div></div>
          <div>
            <Title>
              <p>Login to</p>
              <p>Your Account</p>
            </Title>
            <Alerts />
            <form onSubmit={onSubmit}>
              <StyledInput
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                onChange={onChange}
                required
              />

              <StyledInput
                type='password'
                name='password'
                placeholder='Password'
                value={password}
                onChange={onChange}
                required
              />
              <ForgotPassword>
                <Link to='/password-reset'>
                  <Underlined>Forgot your password?</Underlined>
                </Link>
              </ForgotPassword>

              <Button
                onClick={onSubmit}
                block={'true'}
                color={!!loading ? 'success' : 'light'}
                disabled={!!loading}
                fontSize={1.2}
              >
                {!!loading ? 'LOADING...' : 'LOG IN'}
              </Button>
              <FooterLink>
                Don't have an account?{' '}
                <Link to='/register'>
                  <Underlined>Sign up</Underlined>
                </Link>
              </FooterLink>
            </form>
          </div>

          <StyledFooter>
            <Socials>
              <a href='https://www.facebook.com/grouprapport/'>
                <Icon
                  iconType='facebook'
                  size={1.5}
                  margin='auto'
                  color={'light'}
                />
              </a>
              <a href='https://twitter.com/GroupRapport'>
                <Icon
                  iconType='twitter'
                  size={1.5}
                  margin='auto'
                  color={'light'}
                />
              </a>
              <a href='https://www.linkedin.com/company/group-rapport-ltd/about/'>
                <Icon
                  iconType='linkedin'
                  size={1.5}
                  margin='auto'
                  color={'light'}
                />
              </a>
            </Socials>
            <OutsideLinks>
              <a href='http://grouprapport.com/privacy-policy/'>Terms</a>
              <a href='https://www.grouprapport.com/'>Group Rapport</a>
              <a href='http://grouprapport.com/newsletter/'>News</a>

              <a href='mailto:enquiries@rapportteam.com'>Contact</a>
            </OutsideLinks>
          </StyledFooter>
        </Wrapper>
      ) : (
        <PageLoader />
      )}
    </Container>
  );
};

export default Login;
