import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';

import Progress from '../Feedback/ProgressBar';
import { Button } from '../Controls';
import Alerts from '../Feedback/Alerts';

//import Logo from '../../assets/logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  margin: 0;
  margin: auto;
  padding: 1rem;
  max-height: 800px;
`;
/*
const StyledLogo = styled.img`
  margin: auto;
  width: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
`;
*/
const Title = styled.h1`
  padding: 6rem 0 2rem 0;
`;

const LabelGroup = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  }
`;

const StyledInput = styled.input`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  display: block;
  width: 100%;
  margin: 1rem 0 2rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border: 3px solid ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border-radius: 2px;
  &:focus,
  &:active {
    outline: none;
    border: 3px solid ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
  }
`;

const FooterLink = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.primary[0]}, 1)`};
  padding-top: 2rem;
`;

const Underlined = styled.span`
  text-decoration: underline;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Register = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated, loadUser } =
    authContext;

  useEffect(() => {
    loadUser();
    if (isAuthenticated) {
      props.history.push('/');
    }

    if (!!error) {
      setAlert(error.message, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    email2: '',
    name: '',
    password: '',
    password2: '',
  });

  const { email, email2, name, password, password2 } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const steps = {
    total: 3,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (currentStep !== steps.total) {
      nextStep();
    }
    if (
      email === '' ||
      email2 === '' ||
      password === '' ||
      password2 === '' ||
      name === ''
    ) {
      setAlert('Please enter all fields', 'danger');
    } else if (email !== email2) {
      setAlert('Emails do not match', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register(email, password, name, window.location.href);
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    currentStep !== steps.total && setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    currentStep !== 1 && setCurrentStep(currentStep - 1);
  };

  return (
    <Wrapper>
      <Title>Seminars</Title>
      <Progress
        total={steps.total}
        current={currentStep}
        margin='0 0 2rem 0'
        title='Register'
      />
      <Alerts />
      <form onSubmit={onSubmit}>
        {currentStep === 1 && (
          <>
            <div>
              <LabelGroup>
                <label htmlFor='name'>Name</label>
              </LabelGroup>
              <StyledInput
                type='text'
                name='name'
                placeholder='e.g. Barack Obama'
                value={name}
                onChange={onChange}
                required
              />
            </div>
          </>
        )}
        {currentStep === 2 && (
          <>
            <div>
              <LabelGroup>
                <label htmlFor='email'>Email</label>
              </LabelGroup>
              <StyledInput
                type='email'
                name='email'
                placeholder='e.g. obama@email.com'
                value={email}
                onChange={onChange}
                required
              />
            </div>
            <div>
              <LabelGroup>
                <label htmlFor='email2'>Confirm Email</label>
              </LabelGroup>
              <StyledInput
                type='email'
                name='email2'
                placeholder='e.g. obama@email.com'
                value={email2}
                onChange={onChange}
                required
              />
            </div>
          </>
        )}
        {currentStep === 3 && (
          <>
            <div>
              <LabelGroup>
                <label htmlFor='password'>Password</label>
              </LabelGroup>
              <StyledInput
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                required
              />
            </div>
            <div>
              <LabelGroup>
                <label htmlFor='password2'>Confirm Password</label>
              </LabelGroup>
              <StyledInput
                type='password'
                name='password2'
                value={password2}
                onChange={onChange}
                required
              />
            </div>
          </>
        )}
      </form>
      <BtnGroup>
        {currentStep === 1 ? (
          <div></div>
        ) : (
          <Button onClick={backStep} color={'outline-dark'} fontSize={1}>
            Back
          </Button>
        )}
        {currentStep === steps.total ? (
          <Button
            onClick={onSubmit}
            color={'success'}
            fontSize={1}
            margin={'0'}
          >
            {'Register'}
          </Button>
        ) : (
          <Button
            onClick={nextStep}
            color={'success'}
            fontSize={1}
            margin={'0'}
          >
            Next
          </Button>
        )}
      </BtnGroup>

      <FooterLink>
        Already have an account?
        <Link to='/login'>
          {' '}
          <Underlined>Login here</Underlined>
        </Link>
      </FooterLink>
    </Wrapper>
  );
};

export default Register;
