import React, { useReducer } from 'react';
import firebase, { db } from '../firebase';
import AuthContext from './authContext';
import authReducer from './authReducer';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  USER_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  PASSWORD_RESET,
  VERIFY_EMAIL,
  AUTH_LOADING,
  UPDATE_USER,
} from '../types';

const AuthState = (props) => {
  const initalState = {
    isAuthenticated: null,
    loading: true,
    user: null,
    userData: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initalState);

  // Load User
  const loadUser = (props) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        loadUserData(user)
          .then((userData) => {
            dispatch({
              type: USER_LOADED,
              payload: {
                user,
                userData: userData,
              },
            });
          })
          .catch((err) => {
            dispatch({
              type: AUTH_ERROR,
            });
          });
      } else {
        dispatch({
          type: AUTH_ERROR,
        });
      }
    });
  };

  const loadUserData = (user) => {
    const UserRecord = db
      .collection('users')
      .where('authUserID', '==', user.uid)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length !== 0 && querySnapshot.docs[0].exists) {
          return querySnapshot.docs[0].data();
        }
        return null;
      })
      .catch((err) => {
        return err;
      });
    return UserRecord;
  };

  // Register User
  const register = async (email, password, name, windowLocation) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        res.user
          .updateProfile({
            displayName: name,
          })
          .then(() => {
            handleNewAuthUser(email, name, res.user.uid, windowLocation);
            dispatch({
              type: REGISTER_SUCCESS,
              payload: res.data,
            });
            loadUser();
          })
          .catch((err) => {
            console.log(err);
            dispatch({
              type: REGISTER_FAIL,
              payload: err,
            });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });
  };

  // Create user Record
  const handleNewAuthUser = (email, name, authID, windowLocation) => {
    const newUser = db.collection('users').doc();

    const newUserData = {
      name,
      email,
      authUserID: authID,
      id: newUser.id,
      status: 'New',
      tenant: 'group-rapport',
      source: windowLocation,
    };
    //call function with tenant
    newUser
      .set({
        ...newUserData,
        created: firebase.firestore.Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => {
        dispatch({
          type: REGISTER_FAIL,
          payload: err,
        });
      });

    return newUser;
  };

  // Login User
  const login = async (formData) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(formData.email, formData.password)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });

        loadUser();
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      });
  };

  // Logout
  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        dispatch({ type: LOGOUT });
      })
      .catch(function (err) {
        console.log(err);
        dispatch({
          type: LOGIN_FAIL,
          payload: err.response.data.msg,
        });
      });
  };

  // Send Password Reset
  const passwordReset = async (email) => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({
          type: AUTH_ERROR,
          payload: {
            code: 'password-reset-success',
            message:
              'Message Send! Please check your emails for your reset link',
          },
        });
        dispatch({
          type: PASSWORD_RESET,
        });
        loadUser();
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };

  // Send Email Verification
  const verifyEmail = async () => {
    dispatch({
      type: AUTH_LOADING,
    });
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        dispatch({
          type: VERIFY_EMAIL,
        });
        dispatch({
          type: AUTH_ERROR,
          payload: {
            code: 'email-verify-danger',
            message:
              'Message Send! Please check your emails for your verification link',
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
          payload: err,
        });
      });
  };

  // Update UserData
  const updateUserData = async (user) => {
    db.collection('users')
      .doc(user.id)
      .update({ ...user })
      .then(() => {
        dispatch({
          type: UPDATE_USER,
          payload: user,
        });
      })
      .catch((err) => {
        dispatch({
          type: USER_ERROR,
          payload: err,
        });
      });
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        userData: state.userData,
        error: state.error,
        register,
        loadUser,
        login,
        logout,
        clearErrors,
        passwordReset,
        verifyEmail,
        updateUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
