import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SeminarsContext from '../../context/seminars/seminarsContext';
import AuthContext from '../../context/auth/authContext';

import LoadingSpinner from '../Feedback/LoadingSpinner';
import Icon from '../UI/Icon';
import LargeSeminarCard from '../Seminar/LargeSeminarCard';
import LineBreak from '../UI/LineBreak';
import SeminarList from '../Seminar/SeminarList';

const CenteredSpinner = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 1rem;
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0;
`;

const SubTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem 0;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  display: flex;
  align-items: center;
`;

const Dashboard = () => {
  const seminarsContext = useContext(SeminarsContext);
  const authContext = useContext(AuthContext);
  useEffect(() => {
    if (!seminarsContext.seminars) {
      seminarsContext.getSeminars();
    } else {
      seminarsContext.setCurrent(seminarsContext.seminars.next);
    }
    // eslint-disable-next-line
  }, [seminarsContext.seminars]);

  return (
    <Container>
      {!!seminarsContext.current ? (
        <>
          <Title>Seminars</Title>
          <FlexGroup>
            <SubTitle>Next Scheduled</SubTitle>
            <StyledLink to='/seminars/details'>
              Details{' '}
              <Icon iconType='chevron-right' size={1} color='linkBlue' />
            </StyledLink>
          </FlexGroup>
          <LargeSeminarCard
            seminar={seminarsContext.current}
            authID={authContext.user.uid}
          />
          <LineBreak />
          {seminarsContext.seminars.future &&
            seminarsContext.seminars.future.length > 1 && (
              <>
                <FlexGroup>
                  <SubTitle>Upcoming Seminars</SubTitle>
                  <StyledLink to='/seminars/all'>
                    See all{' '}
                    <Icon iconType='chevron-right' size={1} color='linkBlue' />
                  </StyledLink>
                </FlexGroup>
                <SeminarList
                  seminars={seminarsContext.seminars.future}
                  reverse={true}
                  includeRegisterButton={true}
                />
              </>
            )}
          {seminarsContext.seminars.past &&
            seminarsContext.seminars.past.length > 0 && (
              <>
                <FlexGroup>
                  <SubTitle>Recent Seminars</SubTitle>
                  <StyledLink to='/seminars/all'>
                    See all{' '}
                    <Icon iconType='chevron-right' size={1} color='linkBlue' />
                  </StyledLink>
                </FlexGroup>
                <SeminarList seminars={seminarsContext.seminars.past} />
              </>
            )}
        </>
      ) : (
        <CenteredSpinner>
          <LoadingSpinner />
        </CenteredSpinner>
      )}
    </Container>
  );
};

export default Dashboard;
