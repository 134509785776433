import React, { useEffect, useState } from 'react';
import { uid } from 'uid';
import Moment from 'react-moment';
import firebase, { db } from '../../context/firebase';
import styled from 'styled-components';
import Icon from '../UI/Icon';
import Loader from '../Feedback/LoadingSpinner';
import { Button } from '../Controls';
import { Fragment } from 'react';

const Wrapper = styled.div`
  padding: 0 1rem;
  max-width: 800px;
`;

const SubHeader = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
`;

const QuestionWrapper = styled.div`
  padding: 0.5rem;
  background: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border-radius: 10px;
  margin-bottom: 0.5rem;
`;

const QuestionTitleLine = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const QuestionText = styled.div`
  text-transform: none;
`;

const QuestionMetas = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
`;

const PostMetaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.7rem;
  margin-right: 0.25rem;
  min-width: 40px;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
`;

const LoadingWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled.input`
  font-family: ${(props) => props.theme.fonts.mainFontFamily};
  display: block;
  width: 100%;

  padding: 0.5rem 1rem;
  font-size: 1rem;
  background: white;
  border: 2px solid ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
  border-radius: 25px;
  &:focus,
  &:active {
    outline: none;
    border: 3px solid ${(props) => `rgba(${props.theme.colors.success[0]}, 1)`};
  }
`;

const QuestionAskWrapper = styled.div`
  margin-bottom: 1rem;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.25rem;
`;

const ActionsGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RepliesWrapper = styled.div``;

const Reply = styled.div`
  background-color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 0.8)`};
  padding: 0.5rem;
  margin: 0.25rem 0;
  border-radius: 25px;
  border-top-left-radius: 0px;
`;

const ReplyText = styled.div`
  text-transform: none;
`;

const ReplyDate = styled.div`
  font-size: 0.7rem;
  color: ${(props) => `rgba(${props.theme.colors.neutral[3]}, 1)`};
`;

const PaddingGroup = styled.div`
  padding-top: 1rem;
`;

const Questions = ({ seminarID, authUserID }) => {
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questionToAsk, setQuestionToAsk] = useState('');
  const [replyToPost, setReplyToPost] = useState('');
  const [questions, setQuestions] = useState([]); // eslint-disable-next-line
  const [questionChange, setQuestionChange] = useState(false);
  const [commentsToShow, setCommentsToShow] = useState(false);
  useEffect(() => {
    let mounted = true;
    setLoadingQuestions(true);
    const loadedQuestions = [];
    if (!!seminarID) {
      db.collection('seminarquestions')
        .where('seminarID', '==', seminarID)
        .orderBy('created')
        .get()
        .then((querySnapshot) => {
          if (mounted) {
            querySnapshot.forEach((doc) => {
              const question = { ...doc.data() };
              if (!question.deleted) {
                question.id = doc.id;
                question.created = question.created.toDate();
                if (question.replies) {
                  const mutatedReplies = [];
                  question.replies.forEach((reply) => {
                    if (!reply.deleted) {
                      mutatedReplies.push(reply);
                    }
                  });
                  question.replies = mutatedReplies;
                }
                loadedQuestions.push(question);
              }
            });
            setQuestions(loadedQuestions.reverse());
            setLoadingQuestions(false);
          }
        })
        .catch((err) => {
          setLoadingQuestions(false);
          console.log(err);
        });
    }
    return function cleanup() {
      mounted = false;
    };
  }, [seminarID]);

  const handleQuestionToAskChange = (e) => {
    e.preventDefault();
    setQuestionToAsk(e.target.value);
  };

  const handleQuestionSubmit = () => {
    const newQuestion = db.collection('seminarquestions').doc();
    const newQuestionData = {
      question: questionToAsk,
      seminarID: seminarID,
      authUserID: authUserID,
      id: newQuestion.id,
      created: firebase.firestore.Timestamp.now(),
    };
    newQuestion
      .set({
        ...newQuestionData,
      })
      .then(() => {
        const update = {
          ...newQuestionData,
          created: new Date(),
        };
        const updatedQuestions = questions;
        updatedQuestions.unshift(update);
        setQuestions(updatedQuestions);
        setQuestionToAsk('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteQuestion = (questionID, questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = { ...questions[questionIndex], deleted: true };

    db.collection('seminarquestions')
      .doc(questionID)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReplyToPostChange = (e) => {
    e.preventDefault();
    setReplyToPost(e.target.value);
  };

  const handleReplySubmit = (questionIndex) => {
    const newReplyData = {
      reply: replyToPost,
      authUserID: authUserID,
      id: uid(16),
      created: firebase.firestore.Timestamp.now(),
    };
    const questionUpdate = { ...questions[questionIndex] };
    !!questionUpdate.replies
      ? questionUpdate.replies.push(newReplyData)
      : (questionUpdate.replies = [newReplyData]);

    db.collection('seminarquestions')
      .doc(questionUpdate.id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setReplyToPost('');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReplyDelete = (questionIndex, replyIndex) => {
    setQuestionChange(true);
    const questionUpdate = {
      ...questions[questionIndex],
    };
    questionUpdate.replies[replyIndex] = {
      ...questionUpdate.replies[replyIndex],
      deleted: true,
    };

    db.collection('seminarquestions')
      .doc(questions[questionIndex].id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpvote = (questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = { ...questions[questionIndex] };
    !!questionUpdate.upvotes
      ? questionUpdate.upvotes.push(authUserID)
      : (questionUpdate.upvotes = [authUserID]);

    db.collection('seminarquestions')
      .doc(questionUpdate.id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveUpvote = (questionIndex) => {
    setQuestionChange(true);
    const questionUpdate = {
      ...questions[questionIndex],
    };

    const index = questionUpdate.upvotes.indexOf(authUserID);

    if (index > -1) {
      questionUpdate.upvotes.splice(index, 1);
    }

    db.collection('seminarquestions')
      .doc(questions[questionIndex].id)
      .update({ ...questionUpdate })
      .then(() => {
        const updatedQuestions = questions;
        updatedQuestions[questionIndex] = questionUpdate;
        setQuestions(updatedQuestions);
        setQuestionChange(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleCommentsSection = (questionID) => {
    setReplyToPost('');
    commentsToShow === questionID
      ? setCommentsToShow(false)
      : setCommentsToShow(questionID);
  };

  return (
    <Wrapper>
      {!!questions && (
        <>
          <SubHeader>Comments</SubHeader>
          <QuestionAskWrapper>
            <FlexGroup>
              <Icon iconType='plusfill' size={2} color={'midGrey'} />
              <StyledInput
                placeholder='Post your own question to the team...'
                type='text'
                value={questionToAsk}
                onChange={(e) => {
                  handleQuestionToAskChange(e);
                }}
              ></StyledInput>
            </FlexGroup>
            {questionToAsk !== '' && (
              <BtnGroup>
                <Button
                  size={'small'}
                  color={'success'}
                  onClick={() => handleQuestionSubmit()}
                >
                  Post public question
                </Button>
                <Button
                  onClick={() => setQuestionToAsk('')}
                  size={'small'}
                  color={'light'}
                >
                  Cancel
                </Button>
              </BtnGroup>
            )}
          </QuestionAskWrapper>
          {!!loadingQuestions ? (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          ) : (
            <>
              {questions.map((question, questionIndex) => (
                <Fragment key={question.id}>
                  {!question.deleted && (
                    <QuestionWrapper>
                      <QuestionTitleLine>
                        <Icon
                          iconType='comments'
                          margin={'0 0.25rem 0 0'}
                          size={1}
                          color={'dark'}
                        />
                        <Moment format='Do MMMM YYYY h:mm:ss a'>
                          {question.created}
                        </Moment>
                      </QuestionTitleLine>
                      <QuestionText>{question.question}</QuestionText>
                      <QuestionMetas>
                        {question.authUserID === authUserID && (
                          <PostMetaItem>
                            <Button
                              onClick={() =>
                                handleDeleteQuestion(question.id, questionIndex)
                              }
                              size={'small'}
                              color={'danger'}
                            >
                              Delete
                            </Button>
                          </PostMetaItem>
                        )}
                        <PostMetaItem
                          onClick={() =>
                            question.authUserID !== authUserID &&
                            (!question.upvotes ||
                            !question.upvotes.includes(authUserID)
                              ? handleUpvote(questionIndex)
                              : !!question.upvotes.includes(authUserID) &&
                                handleRemoveUpvote(questionIndex))
                          }
                        >
                          <Icon
                            iconType='upvote'
                            size={1}
                            color={
                              !!question.upvotes &&
                              !!question.upvotes.includes(authUserID)
                                ? 'success'
                                : 'midGrey'
                            }
                          />{' '}
                          {!!question.upvotes &&
                            question.upvotes.length &&
                            question.upvotes.length}
                        </PostMetaItem>
                        <PostMetaItem
                          onClick={() =>
                            handleToggleCommentsSection(question.id)
                          }
                        >
                          <Icon iconType='comment' size={1} color='midGrey' />{' '}
                          {!!question.replies &&
                            !!question.replies.length > 0 &&
                            question.replies.length}
                        </PostMetaItem>
                      </QuestionMetas>
                      {commentsToShow === question.id && (
                        <PaddingGroup>
                          <ActionsGroup>
                            <StyledInput
                              placeholder='Add a comment...'
                              type='text'
                              value={replyToPost}
                              onChange={(e) => {
                                handleReplyToPostChange(e);
                              }}
                            ></StyledInput>
                            {replyToPost !== '' && (
                              <Button
                                onClick={() => handleReplySubmit(questionIndex)}
                                size={'small'}
                                color={'primary'}
                              >
                                Post
                              </Button>
                            )}
                          </ActionsGroup>
                          {question.replies && (
                            <RepliesWrapper>
                              {question.replies.map((reply, replyIndex) => (
                                <Fragment key={reply.id}>
                                  {!reply.deleted && (
                                    <Reply>
                                      <ReplyDate>
                                        <Moment format='Do MMMM YYYY h:mm:ss a'>
                                          {reply.created.toDate()}
                                        </Moment>
                                      </ReplyDate>
                                      <ReplyText>{reply.reply}</ReplyText>
                                      {reply.authUserID === authUserID && (
                                        <Button
                                          onClick={() =>
                                            handleReplyDelete(
                                              questionIndex,
                                              replyIndex
                                            )
                                          }
                                          size={'small'}
                                          color={'danger'}
                                        >
                                          Delete
                                        </Button>
                                      )}
                                    </Reply>
                                  )}
                                </Fragment>
                              ))}
                            </RepliesWrapper>
                          )}
                        </PaddingGroup>
                      )}
                    </QuestionWrapper>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Questions;
