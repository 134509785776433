import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from './GlobalStyles';

const StylesWrapper = ({ children }) => {
  const theme = {
    colors: {
      neutral: [
        '0, 0, 0',
        '34, 34, 34',
        '68, 68, 68',
        '153, 153, 153',
        '238, 238, 238',
        '255, 255, 255',
      ],
      primary: [
        '50, 71, 102',
        '50, 71, 102',
        '50, 71, 102',
        '50, 71, 102',
        '50, 71, 102',
      ],
      secondary: [
        '237, 155, 64',
        '237, 155, 64',
        '237, 155, 64',
        '237, 155, 64',
        '237, 155, 64',
      ],
      tertiary: [
        '35, 37, 40',
        '35, 37, 40',
        '35, 37, 40',
        '35, 37, 40',
        '35, 37, 40',
      ],
      danger: [
        '230, 57, 70',
        '230, 57, 70',
        '230, 57, 70',
        '230, 57, 70',
        '230, 57, 70',
      ],
      success: [
        '87, 158, 114',
        '87, 158, 114',
        '87, 158, 114',
        '87, 158, 114',
        '87, 158, 114',
      ],
      linkBlue: ['15, 131, 254'],
    },
    fonts: {
      mainFontFamily: "'Oxygen', sans-serif",
    },
    styles: {
      borderRadius: '10px',
      mdBreakpoint: '1100px',
      smBreakpoint: '700px',
      onHoverOpacity: 0.7,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default StylesWrapper;
