import React, { Fragment, useState, useEffect } from 'react';

const Countdown = ({ TargetDate, title }) => {
  const [countdown, setCountdown] = useState('');
  let countDownDate = new Date(TargetDate).getTime();

  useEffect(() => {
    const timer = setTimeout(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setCountdown(
        days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
      );
      if (distance < 0) {
        setCountdown('');
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [countDownDate, countdown]);

  return (
    <Fragment>
      {countdown !== '' && (
        <>
          <div>{title ? title : 'Countdown'}</div>
          <div>{countdown}</div>
        </>
      )}
    </Fragment>
  );
};

export default Countdown;
