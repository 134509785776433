import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import SeminarsContext from '../../context/seminars/seminarsContext';

import LoadingSpinner from '../Feedback/LoadingSpinner';
import Icon from '../UI/Icon';
import SeminarList from '../Seminar/SeminarList';

const CenteredSpinner = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 1rem;
`;

const FlexGroup = styled.div`
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 0;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  display: flex;
  align-items: center;
`;

const Recent = () => {
  const seminarsContext = useContext(SeminarsContext);
  useEffect(() => {
    if (!seminarsContext.seminars) {
      seminarsContext.getSeminars();
    } else {
      seminarsContext.setCurrent(seminarsContext.seminars.next);
    }
    // eslint-disable-next-line
  }, [seminarsContext.seminars]);

  return (
    <Container>
      {seminarsContext.seminars &&
      seminarsContext.seminars.past &&
      seminarsContext.seminars.past.length > 0 ? (
        <>
          <FlexGroup>
            <Title>Recent Seminars</Title>
          </FlexGroup>
          <FlexGroup>
            <div></div>
            <StyledLink to='/seminars/all'>
              See all{' '}
              <Icon iconType='chevron-right' size={1} color='linkBlue' />
            </StyledLink>
          </FlexGroup>
          <SeminarList seminars={seminarsContext.seminars.past} />
        </>
      ) : (
        <CenteredSpinner>
          <LoadingSpinner />
        </CenteredSpinner>
      )}
    </Container>
  );
};

export default Recent;
