import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import AuthContext from '../../context/auth/authContext';
import SeminarsContext from '../../context/seminars/seminarsContext';

import { Button } from '../Controls';
import LoadingSpinner from '../Feedback/LoadingSpinner';
import Countdown from '../UI/Countdown';
import Questions from '../Seminar/Questions';
import Icon from '../UI/Icon';
import LineBreak from '../UI/LineBreak';

import PlaceholderImg from '../../assets/jaime-lopes-0RDBOAdnbWM-unsplash.jpg';

const CenteredSpinner = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgWrapper = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[5]}, 1)`};
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 350px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `linear-gradient(
      0deg, rgba(${props.theme.colors.primary[0]}, 0.8), rgba(${props.theme.colors.neutral[2]}, 0.5)), url(${props.img}) `};
`;

const Time = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 0.8)`};
  font-size: 1rem;
  display: flex;
  > * {
    margin-right: 0.5rem;
  }
`;

const FlexGroup = styled.div`
  display: flex;
  font-size: 1.15rem;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const Title = styled.h2`
  padding: 0.5rem 0;
  font-size: 2rem;
  font-weight: bold;
`;

const Lighter = styled.p`
  color: ${(props) => `rgba(${props.theme.colors.neutral[4]}, 1)`};
`;

const SubWrapper = styled.div`
  padding: 1rem;
`;

const JoiningBox = styled.div``;
const CallDetails = styled.div`
  margin: 0.5rem 0;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const Strong = styled.div`
  font-weight: bold;
  color: ${(props) => `rgba(${props.theme.colors.neutral[1]}, 1)`};
`;

const JoiningTitle = styled.div`
  padding-bottom: 0.5rem;
  font-size: 1.3rem;
  font-weight: bold;
`;

const RegisterBox = styled(Link)`
  padding: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${(props) => `rgba(${props.theme.colors.linkBlue[0]}, 1)`};
  background: ${(props) => `rgba(${props.theme.colors.primary[0]}, 0.3)`};
  border-radius: 10px;
  > p {
    margin-top: 0.25rem;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Description = styled.div`
  max-width: 800px;
  > h3 {
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0.5rem 0;
  }
  p {
    line-height: 130%;
    color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
  }
`;

const SubTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0.5rem 0 0 0;
`;

const SpeakerTile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
  max-width: 800px;
`;
const SpeakerImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
const SpeakerNameGroup = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  width: 100%;
`;
const SpeakerName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SpeakerJob = styled.div``;
const SpeakerCompany = styled.div`
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;
const StyledIFrame = styled.iframe`
  height: 60vw;
  width: 100%;
  margin: auto;
  max-width: 1100px;
  max-height: 800px;
  border: none;
  padding: 1rem 0;
`;

const Small = styled.span`
  padding-left: 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  color: ${(props) => `rgba(${props.theme.colors.neutral[2]}, 1)`};
`;

const SeminarPage = () => {
  const history = useHistory();
  const [toggle, setToggle] = useState('video');
  const authContext = useContext(AuthContext);

  const seminarsContext = useContext(SeminarsContext);
  const [seminarData, setSeminarData] = useState();

  useEffect(() => {
    if (!!seminarsContext.current) {
      const dateToCheck = moment(new Date()).add(-240, 'm').toDate();

      const update = { ...seminarsContext.current };
      update.startTime < dateToCheck
        ? (update.seminarInThePast = true)
        : (update.seminarInThePast = false);
      setSeminarData(update);
    } else {
      history.push('/');
    }
  }, [seminarsContext, history]);

  return (
    <>
      {!!seminarData ? (
        <>
          <ImgWrapper img={seminarData.thumbnailImgUrl}>
            <FlexGroup onClick={() => history.push('/')}>
              <Icon iconType='chevron-left' size={1.15} color='light' /> Back
            </FlexGroup>
            <div>
              {!seminarData.seminarInThePast ? (
                <Time>
                  <Countdown
                    TargetDate={seminarData.startTime}
                    title='Starts In:'
                  />
                </Time>
              ) : (
                <Time>On Demand Video</Time>
              )}
              <Title>{seminarData.title}</Title>
              <Lighter>
                {moment(seminarData.startTime).format(
                  'ddd Do MMMM YYYY h:mm a'
                )}
              </Lighter>
            </div>
          </ImgWrapper>
          <SubWrapper>
            {!seminarData.seminarInThePast ? (
              <>
                <JoiningTitle>
                  Joining Details<Small> - Zoom Meeting</Small>
                </JoiningTitle>
                {!!seminarData.registeredUsers &&
                seminarData.registeredUsers.includes(authContext.user.uid) ? (
                  <>
                    {!!seminarData.callDetails && (
                      <JoiningBox>
                        {!!seminarData.callDetails.linkURL && (
                          <CallDetails>
                            <a href={seminarData.callDetails.linkURL}>
                              <Strong>Meeting Link:</Strong>{' '}
                              {seminarData.callDetails.linkURL}
                            </a>
                          </CallDetails>
                        )}
                        {!!seminarData.callDetails.meetingID && (
                          <CallDetails>
                            <Strong>Meeting ID:</Strong>{' '}
                            {seminarData.callDetails.meetingID}
                          </CallDetails>
                        )}
                        {!!seminarData.callDetails.dailInNumber && (
                          <CallDetails>
                            <a
                              href={`tel: ${seminarData.callDetails.dailInNumber}`}
                            >
                              <Strong>Dial In:</Strong>{' '}
                              {seminarData.callDetails.dailInNumber}
                            </a>
                          </CallDetails>
                        )}
                      </JoiningBox>
                    )}
                    <LineBreak />
                  </>
                ) : (
                  <RegisterBox to='/seminars/register'>
                    <Icon
                      iconType='profile'
                      size={3}
                      color='light'
                      margin='auto'
                    />
                    <p>REGISTER TO UNLOCK</p>
                  </RegisterBox>
                )}
              </>
            ) : seminarData.videoLink && seminarData.presentationURL ? (
              <>
                <SubTitle>On Demand Content</SubTitle>

                {toggle === 'video' && (
                  <StyledIFrame
                    src={seminarData.videoLink}
                    frameborder='0'
                    allow='autoplay; fullscreen'
                    allowfullscreen
                  />
                )}
                {toggle === 'presentation' && (
                  <StyledIFrame
                    src={seminarData.presentationURL}
                    frameborder='0'
                    allow='autoplay; fullscreen'
                    allowfullscreen
                  />
                )}
                <BtnGroup>
                  {toggle === 'video' ? (
                    <Button
                      color='light'
                      size='small'
                      bold={true}
                      onClick={() => {
                        setToggle('presentation');
                      }}
                    >
                      VIEW SLIDES
                    </Button>
                  ) : (
                    <Button
                      color='light'
                      size='small'
                      bold={true}
                      onClick={() => {
                        setToggle('video');
                      }}
                    >
                      VIEW VIDEO
                    </Button>
                  )}
                </BtnGroup>
              </>
            ) : (
              <div>Seminar Recording & Presentation Coming Soon</div>
            )}
            {seminarData.description && (
              <>
                <Description>
                  <h3>Overview</h3>
                  <p>{seminarData.description}</p>
                </Description>{' '}
                <LineBreak />
              </>
            )}
            {!!seminarData.speakers && !!seminarData.speakers.length && (
              <>
                <SubTitle>Speakers</SubTitle>
                {seminarData.speakers.map((speaker, index) => (
                  <SpeakerTile key={index}>
                    {speaker.photoUrl ? (
                      <SpeakerImg
                        src={speaker.photoUrl}
                        alt='speaker profile'
                      />
                    ) : (
                      <SpeakerImg src={PlaceholderImg} alt='speaker profile' />
                    )}
                    <SpeakerNameGroup>
                      <SpeakerName>
                        <p>{speaker.name} </p>
                        {speaker.linkedIn && (
                          <Link to={speaker.linkedIn}>
                            <Icon
                              iconType='linkedin'
                              size={1}
                              color='linkBlue'
                              margin='auto'
                            />
                          </Link>
                        )}
                      </SpeakerName>
                      <SpeakerJob>{speaker.jobTitle}</SpeakerJob>
                      <SpeakerCompany>{speaker.company}</SpeakerCompany>
                    </SpeakerNameGroup>
                  </SpeakerTile>
                ))}
                <LineBreak />
              </>
            )}
          </SubWrapper>
          <Questions
            seminarID={seminarData.id}
            authUserID={authContext.user.uid}
          />
        </>
      ) : (
        <CenteredSpinner>
          <LoadingSpinner />
        </CenteredSpinner>
      )}
    </>
  );
};

export default SeminarPage;
