import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';

import SeminarsState from './context/seminars/SeminarsState';

import PrivateRoute from './routes/PrivateRoute';

import StylesWrapper from './components/Layout/StylesWrapper';

import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import PasswordReset from './components/Auth/PasswordReset';
import VerifyEmail from './components/Auth/VerifyEmail';

import Profile from './components/Pages/Profile';
import Dashboard from './components/Pages/Dashboard';
import SeminarDetails from './components/Pages/SeminarDetails';
import SeminarRegister from './components/Pages/SeminarRegister';
import Recent from './components/Pages/Recent';
import All from './components/Pages/All';

const App = () => {
  return (
    <AuthState>
      <AlertState>
        <SeminarsState>
          <StylesWrapper>
            <Router>
              <Switch>
                <PrivateRoute path='/' component={Dashboard} exact />
                <PrivateRoute path='/profile' component={Profile} exact />
                <PrivateRoute
                  path='/seminars/details'
                  component={SeminarDetails}
                  exact
                />
                <PrivateRoute
                  exact
                  path='/seminars/register'
                  component={SeminarRegister}
                />
                <PrivateRoute
                  exact
                  path='/seminars/recent'
                  component={Recent}
                />
                <PrivateRoute exact path='/seminars/all' component={All} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/password-reset' component={PasswordReset} />
                <Route exact path='/verify-email' component={VerifyEmail} />
              </Switch>
            </Router>
          </StylesWrapper>
        </SeminarsState>
      </AlertState>
    </AuthState>
  );
};

export default App;
